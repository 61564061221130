import React from 'react';
import { Image } from 'semantic-ui-react';

const NotFound = () => {
  return (
    <div>
      <h3>404</h3>
    </div>
  );
};

export default NotFound;
